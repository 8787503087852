// extracted by mini-css-extract-plugin
export var articleWrapper = "p_p5";
export var searchResult = "p_qM p_p5";
export var line = "p_fh d_fh d_cW";
export var articleTitle = "p_qN d_cn";
export var articleText = "p_p6 d_cq";
export var authorImage = "p_ll d_ll d_b5 d_X d_cr";
export var authorText = "p_qx d_cf d_cr";
export var textCenter = "p_dt d_dt";
export var searchWrapper = "p_qP d_bx d_bM d_dp d_cj d_X";
export var searchInput = "p_qQ d_cC d_t d_b2";
export var searchBtn = "p_qR d_bZ d_bz d_bM d_Y d_cB d_dy";
export var text = "p_qH d_cp";
export var elementWrapper = "p_mt d_cj";
export var select = "p_qS d_t d_b2";
export var articleImageWrapper = "p_qn d_cq d_X d_S";
export var articleImageWrapperIcon = "p_qp d_cq d_X d_S";
export var articleImageWrapperColumns = "p_qT d_cq d_ff d_X";
export var contentPadding = "p_qV d_cQ";
export var otherPadding = "p_qW d_cK";
export var paginationWrapper = "p_qX";
export var pagination = "p_qY";
export var disabledPagination = "p_qZ";
export var breakMe = "p_q0";
export var activePage = "p_q1";
export var next = "p_q2";
export var pages = "p_q3";
export var searchTitle = "p_q4 d_cp";
export var categoryTitle = "p_q5 d_cp d_X";
export var searchText = "p_q6 d_cr";
export var bold = "p_q7";
export var field = "p_q8";
export var dateTag = "p_qf d_j d_cp d_dn";
export var icon1 = "p_qg d_cM";
export var icon2 = "p_qh d_cM d_cG";
export var tagBtn = "p_qj d_bZ d_cB d_dm d_dy";
export var linksWrapper = "p_qB d_cf d_dt";
export var header = "p_p7 d_t d_X";
export var headerImage = "p_q9 d_bb d_Y d_6 d_5 d_2 d_7";
export var separator = "p_qw d_cW";
export var resultImage = "p_rb";