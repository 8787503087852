// extracted by mini-css-extract-plugin
export var customText = "r_rp d_ds d_cq d_cd";
export var videoIframeStyle = "r_qm d_d3 d_t d_F d_bw d_bZ d_P";
export var customImageWrapper = "r_rg d_cq d_cd d_X";
export var customRow = "r_rj d_bB d_bc";
export var quoteWrapper = "r_gM d_bz d_bM d_cq d_cd";
export var quoteBar = "r_qr d_F";
export var masonryImageWrapper = "r_qy";
export var title = "r_rq";
export var Title3Small = "r_rr w_rr w_rS w_r0";
export var Title3Normal = "r_rs w_rs w_rS w_r1";
export var Title3Large = "r_rt w_rt w_rS w_r2";