import React, { useRef, useState } from 'react';
import Masonry from 'react-masonry-css';
import clsx from 'clsx';
import HTMLParser from 'html-react-parser';

import SectionMedia from '../../LayoutComponents/SectionMedia';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import { formColor, getEmbedUrl } from '../../../../../helper';
import Lightbox from '../../../../Lightbox';
import OSMap from '../../LayoutComponents/OSMap';
import * as mapStyles from '../../../../../map.module.css';

import * as styles from './styles.module.css';

const getImages = (data, result) => {
  data?.forEach((item) => {
    if (item?.type === 'GALLERY' && item?.lightbox) result.push(...item.data?.map((i) => i?.id));
    else if (item?.type === 'ROW' && item.data?.length > 0) {
      item.data?.forEach((d) => {
        if (d?.data?.length > 0) getImages(d?.data, result);
      });
    }
  });
};

const BlankLayout = ({
  section,
  themeData,
  categoryPathList,
  pagePathList,
  articlePathList,
  filePathList,
  images,
  buttons,
}) => {
  const [photo, setPhoto] = useState({ photoIndex: 0, isOpen: false });

  const masonryRef = useRef();

  const sImages = [];
  getImages(section.data, sImages);

  const handleImageClick = (event) => {
    const photoIndex = sImages.findIndex((id) => id === event.target.dataset.imageid);

    if (photoIndex > -1) {
      setPhoto({
        photoIndex,
        isOpen: true,
      });
    }
  };

  const handleCloseClick = () => {
    setPhoto({
      isOpen: false,
    });
  };

  const handleMoveNextRequest = () => {
    setPhoto({
      ...photo,
      photoIndex: (photo.photoIndex + 1) % sImages.length,
    });
  };

  const handleMovePrevRequest = () => {
    setPhoto({
      ...photo,
      photoIndex: (photo.photoIndex + (sImages.length - 1)) % sImages.length,
    });
  };

  const createText = (item, index, quote, componentIndex = null, subIndex, space) => {
    const cols = quote === true || subIndex != null ? '' : 'col-10 col-lg-8';
    const id = `${section._id}_${index}_${componentIndex}_${subIndex}`;

    let content;
    let style;
    const classes = [cols];

    if (item?.type === 'HEADING') {
      content = `<h2>${item.text}</h2>`;
      if (componentIndex == null) classes.push(styles.element);
    } else if (item?.type?.startsWith('PARAGRAPH')) {
      content = `<span>${item.text}</span>`;
      if (!quote && componentIndex == null) classes.push(styles.element);
    }

    if (!quote && space != null) style = { marginBottom: `${space}px`, marginTop: `${space}px` };

    let elem;
    if (content !== undefined) {
      elem = (
        <div key={id} className={clsx(classes)} style={style}>
          {HTMLParser(content)}
        </div>
      );
    }

    return elem;
  };

  const createImage = (item, elementIndex, componentIndex, subIndex, space) => {
    return (
      <div
        key={`${section._id}_Image_${elementIndex}_${componentIndex}_${subIndex}_section`}
        className={clsx(subIndex == null && 'col-10', subIndex != null && item?.type !== 'ICON' && styles.width100)}
        style={{
          marginBottom: space ? `${space}px` : undefined,
          marginTop: space ? `${space}px` : undefined,
          width: item?.content?.icon?.align && subIndex != null ? '100%' : undefined,
        }}
      >
        <div className={subIndex == null ? styles.customImageWrapper : undefined}>
          <CustomSectionMedia
            sizes="100vw"
            sectionId={section._id}
            data={item.content}
            colors={themeData.colors}
            images={images}
            align={item?.content?.icon?.align ?? section?.styles?.align ?? 'Left'}
          />
        </div>
      </div>
    );
  };

  const createVideo = (item, index, componentIndex, subIndex, space) => {
    let videoUrl;
    let video;
    if (item.videoID) videoUrl = getEmbedUrl(item);

    if (videoUrl) {
      video = (
        <div
          className={clsx(subIndex == null && `col-10 col-lg-8 ${styles.element}`)}
          key={`${section._id}_Custom_${index}_${componentIndex}_${subIndex}_Video`}
          style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
        >
          <iframe
            frameBorder="0"
            className={styles.videoIframeStyle}
            src={videoUrl}
            allowFullScreen
            aria-hidden="true"
          />
        </div>
      );
    }

    return video;
  };

  const createGallery = (gllr, componentIndex, subIndex, subSubIndex, space) => {
    const pictures = [];
    const columnsCount = Number(gllr?.columns || 1);
    const sizes = [
      '(max-width: 576px) 100vw',
      `(min-width: 576px and max-width: 768px) ${columnsCount === 1 ? 100 : 50}vw`,
      `(min-width: 768px) ${100 / columnsCount}vw`,
    ].join(', ');
    const { crop, lightbox } = gllr || {};

    gllr?.data?.forEach((item, i) =>
      pictures.push(
        <div key={`${section._id}_Image_${i}_${componentIndex}_${subIndex}_${subSubIndex}`}>
          <div
            className={subIndex == null ? styles.customImageWrapper : styles.imageWrapper}
            style={lightbox ? { cursor: 'pointer' } : undefined}
          >
            <SectionMedia
              mediaType={item.icon ? 'ICON' : 'IMAGE'}
              wrapperStyleName={crop ? 'imageWrapper100' : 'imageContent5'}
              imageStyleName={crop ? 'galleryImg' : 'imageFull'}
              sizes={sizes}
              src={item.CDNLink}
              alt={item.alt}
              data={item}
              sectionId={section._id}
              colors={themeData.colors}
              onImageClick={lightbox ? handleImageClick : undefined}
              images={images}
            />
          </div>
        </div>,
      ),
    );

    let gallery;
    const md = Number(columnsCount) === 1 ? 1 : 2;

    if (crop !== true) {
      const breakpointColumnsObj = {
        default: Number(columnsCount),
        768: md,
        576: 1,
      };

      gallery = (
        <Masonry
          ref={masonryRef}
          breakpointCols={breakpointColumnsObj}
          style={{ display: 'flex', width: '100%' }}
          className=""
          columnClassName=""
        >
          {pictures.map((pic, i) => (
            <div key={`${section._id}_Gallery_Image${componentIndex}${i}`} className={styles.masonryImageWrapper}>
              {pic}
            </div>
          ))}
        </Masonry>
      );
    } else {
      gallery = [];
      const className = `col-12 col-sm-${12 / md} col-md-${12 / Number(columnsCount)}`;
      pictures.forEach((p, i) => {
        const pic = (
          <div className={className} key={`${section._id}_Gallery_Image${componentIndex}${i}`}>
            {p}
          </div>
        );

        return gallery.push(pic);
      });
    }

    return (
      <div
        key={`${section._id}_Gallery_${componentIndex}_${subIndex}_${subSubIndex}`}
        className={subIndex == null ? 'col-10' : styles.gallery}
        style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
      >
        <div className="row" style={subIndex != null && crop ? { width: '100%' } : undefined}>
          {gallery}
        </div>
      </div>
    );
  };

  const createQuote = (item, index, componentIndex, subIndex, space) => (
    <div
      className={clsx(subIndex == null && 'col-8', subIndex == null ? styles.quoteWrapper : styles.quote)}
      key={`${section._id}_Custom_${index}_${componentIndex}_${subIndex}_Quote`}
      style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
    >
      <div className={styles.quoteBar} style={{ backgroundColor: themeData.colors[0] }} />
      <div className={styles.quoteText}>
        <div style={{ height: '10px' }} />
        {createText(item, index, true, componentIndex, subIndex)}
        <div style={{ height: '10px' }} />
      </div>
    </div>
  );

  const createSeparator = (item, index, componentIndex, subIndex, space) => {
    const { width = 20, height = 2, separatorType = 'solid', color = 'COLOR_0', align = null } = item || {};
    const sAlign = styles[`align${align || section?.styles?.align || 'Left'}`];
    let bColor = 'transparent';
    let separator = separatorType;
    if (separatorType !== 'space') {
      bColor = formColor({ solid: color }, false, 1, undefined, themeData?.colors)?.backgroundColor;
    } else separator = 'solid';
    const border = `${separator} ${height}px ${bColor}`;

    return (
      <div
        key={`${section._id}_Separator_${index}`}
        className={clsx(styles.separatorWrapper, sAlign, subIndex == null && styles.element)}
        style={{
          marginBottom: space ? `${space}px` : undefined,
          marginTop: space ? `${space}px` : undefined,
        }}
      >
        <div style={{ width: `${width}%` }}>
          <hr style={{ width: '100%', border: 'none', borderBottom: border }} />
        </div>
      </div>
    );
  };

  const createButton = (item, index, componentIndex, subIndex, space) => {
    return (
      <div
        key={`${section._id}_${subIndex !== undefined ? subIndex : ''}${index}`}
        style={{ marginBottom: space ? `${space}px` : undefined, marginTop: space ? `${space}px` : undefined }}
        className={clsx(subIndex == null && styles.element)}
      >
        <ButtonEditor
          data={item}
          buttons={buttons}
          themeData={themeData}
          pagePathList={pagePathList}
          articlePathList={articlePathList}
          filePathList={filePathList}
          categoryPathList={categoryPathList}
        />
      </div>
    );
  };

  const createMap = (item, index, componentIndex, subIndex, space) => {
    const align = styles[`align${section?.styles?.align || 'Left'}`];
    const { height = 240, zoomLevel = 15, style = 'tinted', popupText, lat, lng } = item || {};
    const themeStyle = `${style}${themeData?.name?.toLowerCase()}`;

    return (
      <div
        key={`${section._id}_Map_${index}_${componentIndex}_${subIndex}`}
        className={clsx(styles.map, align, mapStyles[themeStyle], subIndex == null && styles.element)}
        style={{
          marginBottom: space ? `${space}px` : undefined,
          marginTop: space ? `${space}px` : undefined,
          height: `${height}px`,
        }}
      >
        <OSMap
          lat={lat}
          lng={lng}
          zoom={zoomLevel}
          popupText={popupText}
          id={section._id}
          index={index}
          componentIndex={componentIndex}
          subIndex={subIndex}
        />
      </div>
    );
  };

  const getElement = (item, index, componentIndex, subIndex, space) => {
    let elem;
    if (item?.type === 'HEADING' || item?.type === 'PARAGRAPH/PARAGRAPH') {
      elem = createText(item, index, false, componentIndex, subIndex, space);
    } else if (item?.type === 'PARAGRAPH/QUOTE') {
      elem = createQuote(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'IMAGE' || item?.type === 'ICON') {
      elem = createImage(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'VIDEO') {
      elem = createVideo(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'GALLERY') {
      elem = createGallery(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'BUTTON') {
      elem = createButton(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'SEPARATOR') {
      elem = createSeparator(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'MAP') {
      elem = createMap(item, index, componentIndex, subIndex, space);
    }

    return elem;
  };

  const getAlign = (value) => {
    let align = 'flex-start';
    let textAlign = 'left';
    switch (value) {
      case 'center':
        align = 'center';
        textAlign = 'center';
        break;
      case 'br':
        align = 'flex-end';
        textAlign = 'right';
        break;
      default:
        break;
    }

    return { align, textAlign };
  };

  const align = styles[`align${section?.styles?.align || 'Left'}`];
  const content = [];

  section?.data?.forEach((item, index) => {
    let elem;
    if (item?.type === 'ROW') {
      const style = {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: '16px',
        marginBottom: '16px',
      };

      const vertical = getAlign(item.verticalAlign);
      style.paddingLeft = `${item.gutter ?? 60}px`;
      style.paddingRight = `${item.gutter ?? 60}px`;
      style.justifyContent = getAlign(item.horizontalAlign)?.align ?? 'flex-start';
      style.alignItems = vertical?.align ?? 'flex-start';
      style.textAlign = vertical?.textAlign ?? 'left';
      const cols = item.data.map((col, subIdx) => {
        const elems = [];
        if (col?.data?.length > 0) {
          col.data.forEach((i, subSubIdx) => {
            if (i.type === 'GALLERY') elems.push(getElement(i, index, subIdx, subSubIdx, item.space ?? 20));
            else elems.push(getElement(i, subSubIdx, index, subIdx, item.space ?? 20));
          });
        }

        return (
          <div
            key={`${section._id}_Row_${index}_Col_${subIdx}`}
            className={clsx('col-12', col.columnCount !== 12 && `col-lg-${col.columnCount}`)}
            style={style}
          >
            {elems}
          </div>
        );
      });

      elem = (
        <div
          key={`${section._id}_Row_${index}`}
          id={`${section._id}_Row_${index}`}
          className={`row ${styles.customRow}`}
        >
          {cols}
        </div>
      );
    } else {
      elem = (
        <div key={`${section._id}_Row_${index}`} className={`row ${styles.customRow} ${align}`}>
          {getElement(item, index)}
        </div>
      );
    }
    if (elem) content.push(elem);
  });

  return (
    <div
      className={`${section.styles.full ? 'container-fluid' : 'container'} longarticle`}
      id={`${section._id}_Custom_Main`}
    >
      {sImages.length > 0 && (
        <Lightbox
          mainImageId={sImages[photo.photoIndex]}
          nextImageId={sImages[(photo.photoIndex + 1) % sImages.length]}
          prevImageId={sImages[(photo.photoIndex + (sImages.length - 1)) % sImages.length]}
          onCloseRequest={handleCloseClick}
          onMovePrevRequest={handleMovePrevRequest}
          onMoveNextRequest={handleMoveNextRequest}
          visible={photo.isOpen}
          images={images}
        />
      )}
      {content}
    </div>
  );
};

export default BlankLayout;
