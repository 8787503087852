// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_ts";
export var lbContainerInner = "y_tt";
export var movingForwards = "y_tv";
export var movingForwardsOther = "y_tw";
export var movingBackwards = "y_tx";
export var movingBackwardsOther = "y_ty";
export var lbImage = "y_tz";
export var lbOtherImage = "y_tB";
export var prevButton = "y_tC";
export var nextButton = "y_tD";
export var closing = "y_tF";
export var appear = "y_tG";