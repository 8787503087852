// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_fN d_fN d_bx d_bF d_bG";
export var navbarDividedRight = "x_fP d_fP d_bx d_bG";
export var menuLeft = "x_s6 d_fL d_bx d_dt d_bM d_bG";
export var menuRight = "x_s7 d_fL d_bx d_dt d_bM d_bG";
export var menuCenter = "x_s8 d_fM d_fL d_bx d_dt d_bM d_t d_bB d_bG";
export var menuDivided = "x_pP d_fM d_fL d_bx d_dt d_bM d_t d_bB";
export var navbarItem = "x_pQ d_bv";
export var navbarLogoItemWrapper = "x_fW d_fW d_bz d_bM";
export var burgerToggleVisibleOpen = "x_s9 d_f9 d_bw d_X d_bq";
export var burgerToggleVisible = "x_tb d_f9 d_bw d_X d_bq";
export var burgerToggle = "x_tc d_f9 d_bw d_X d_bq d_X";
export var burgerToggleOpen = "x_td d_f9 d_bw d_X d_bq d_1";
export var burgerInput = "x_tf d_f0 d_t d_F d_bw d_b9 d_dj d_bb d_Y d_6 d_5 d_2 d_7";
export var burgerLine = "x_tg d_fZ";
export var burgerMenuLeft = "x_th d_f4 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuRight = "x_tj d_f5 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuCenter = "x_tk d_f6 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuDivided = "x_tl d_f4 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var secondary = "x_tm d_bz d_bM";
export var staticBurger = "x_tn";
export var menu = "x_tp";
export var navbarDividedLogo = "x_tq";
export var nav = "x_tr";