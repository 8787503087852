// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_rJ d_bB";
export var storyRowWrapper = "v_ht d_ht d_bH";
export var storyLeftWrapper = "v_rK d_bx d_bM";
export var storyWrapperFull = "v_rL d_cB";
export var storyWrapperFullLeft = "v_nL d_cB d_bx d_bM d_bB";
export var contentWrapper = "v_mw d_hv";
export var storyLeftWrapperCenter = "v_rM d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "v_rN d_hB";
export var storyHeader = "v_rP d_hz d_t d_cq";
export var storyHeaderCenter = "v_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "v_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "v_rQ d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "v_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "v_rh d_fd d_X";
export var imageWrapperFull = "v_rR d_t d_F d_bc d_X";